<h1 mat-dialog-title [innerHtml]="data.title"></h1>
<div mat-dialog-content [innerHtml]="data.message"></div>
@if(data.showOnlyOkBtn) {
<div mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onCancel()">
    {{ "common.ok" | translate }}
  </button>
</div>
} @else {
<div mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onCancel()">
    {{ "common.no" | translate }}
  </button>
  <button mat-button mat-dialog-close cdkFocusInitial (click)="onConfirm()">
    {{ "common.yes" | translate }}
  </button>
</div>
}
