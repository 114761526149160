import { Component, ViewChild, OnInit, inject, EventEmitter, Output, HostListener } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import { AuthenticationService } from 'clan-auth';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '@environment/environment';
import { UserService } from '@core/services/user/user.service';
import { error } from 'pdf-lib';
import { OffersService, ProductService } from '@core/services';
import { animate, group, query, state, style, transition, trigger } from '@angular/animations';
import { IdleUserService } from '@core/services/idle/idle.service';
import { CookieService } from 'ngx-cookie-service';
import { EnvironmentBannerComponent } from "@shared/components/environment-banner/environment-banner.component";
import { DialogService } from '@core/services';
import { ConfirmationDialogComponent } from '@shared/components';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    RouterModule,
    CommonModule,
    EnvironmentBannerComponent,
  ],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          width: '16rem',
        })
      ),
      state(
        'closed',
        style({
          width: '8rem',
        })
      ),
      transition('open <=> closed', [animate('0.5s ease-in-out')]),
    ]),
  ],
})
export class NavigationComponent implements OnInit {
  @Output() navigationToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('drawer') drawer: any;
  initials;
  bannerText: string;
  firstNameFirstLetter: string;
  lastNameFirstLetter: string;
  userName: string;
  currentButton = '';
  currentLanguage = 'en';
  roles = [];
  private breakpointObserver = inject(BreakpointObserver);
  isAuthenticated: boolean;
  adminRole = false;
  editRole = false;
  readOnlyRole = false;
  singleAdminRole = false;
  isOpen = true;
  isOpenNav = true;
  notDesktopView = false;
  environment = environment;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet]).pipe(
    map((result) => {
      this.notDesktopView = result.matches;
      if (!result.matches) {
        this.isOpenNav = true;
      }
      return result.matches;
    }),
    shareReplay()
  );
  constructor(
    private authorizationService: AuthorizationService,
    private authService: AuthenticationService,
    private translateService: TranslateService,
    private router: Router,
    private userService: UserService,
    private _offerService: OffersService,
    private idleUserService: IdleUserService,
    private cookieService: CookieService,
    private _dialogService: DialogService,
    private _productService: ProductService,
  ) {}

  reset() {
    if (environment.automaticLogOutActivated) {
      this.idleUserService.reset();
    }
  }

  @HostListener('mousemove') resetListener1() {
    this.reset();
  }
  @HostListener('click') resetListener2() {
    this.reset();
  }
  @HostListener('keypress') resetListener3() {
    this.reset();
  }
  @HostListener('DOMMouseScroll') resetListener4() {
    this.reset();
  }
  @HostListener('mousewheel') resetListener5() {
    this.reset();
  }
  @HostListener('touchmove') resetListener6() {
    this.reset();
  }
  @HostListener('MSPointerMove') resetListener7() {
    this.reset();
  }
  isReportSiteEnabled: boolean;

  ngOnInit(): void {
    this.authorizationService.getAllRoles().subscribe((data) => {
      this.roles = data;
      this.adminRole = this.roles.includes('offertool_admin');
      this.editRole = this.roles.includes('offertool_edit');
      this.readOnlyRole = this.roles.includes('offertool_read_only');
      this.singleAdminRole = this.adminRole && !this.editRole && !this.readOnlyRole;
    });
    this.authorizationService.getIsAuthenticated().subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });
    this.currentLanguage = this.cookieService.get('lang');

    this.authorizationService.userUpdated.subscribe({
      next: (data) => this.getUserData(),
      error: (error) => console.error(error),
    });
    this.getUserData();
    this.getClientData();
  }

  getClientData() {
    let data:any = JSON.parse(localStorage.getItem("fe-configuration"));

    if (data?.config != undefined) {
      this.retriveDataFromConfig(data);
    }
    this._productService.getOTConfiguration().subscribe({
      next: data => {
        this.retriveDataFromConfig(data);
        this.isReportSiteEnabled = environment.config.features?.reports?.enabled;
      },
      error: err => console.error(err)
    })
  }

  retriveDataFromConfig(data) {
    let config:any = data.config

    environment.config = config;
    document.title = config.texts.titleShort
    const favicon:any = document.getElementById('favicon');
    favicon.href = config?.assets?.icon;

    const style = document.createElement('style');
    style.textContent = `:root {--color-primary: ${config.styles.primaryColor};}`;
    document.head.append(style);
    localStorage.setItem("fe-configuration", JSON.stringify(data));
  }

  getUserData() {
    this.userService.getCurrentUserById(this.authorizationService.getId()).subscribe((data) => {
      this.userName = data.firstName + ' ' + data.lastName;
      this.firstNameFirstLetter = data.firstName[0];
      this.lastNameFirstLetter = data.lastName[0];
    });
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
    this._offerService.setLanguage(language);
    this.currentLanguage = language;
  }

  doLogout() {
    const dialogRef = this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
      data: {
          title: this.translateService.instant('cabinet.navigation.logout'),
          message: this.translateService.instant('cabinet.navigation.logoutDescription')
      },
      width: '300px',
  });

    dialogRef.afterClosed().subscribe(result => {
    if (result) {
     
      this.authorizationService.logout();
      this.authService.logout().subscribe(() => this.router.navigate(['/auth']));
      }
    });
  }

  menuOpen() {
    if (this.isOpen == true && this.isOpenNav == true) {
      this.menuSlideOpen();
    }
    this.isOpen = !this.isOpen;

    this.navigationToggle.emit(this.isOpen);
  }

  menuSlideOpen() {
    this.isOpenNav = !this.isOpenNav;
  }
}
