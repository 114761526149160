// environment-banner.component.ts
import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-environment-banner',
  templateUrl: './environment-banner.component.html',
  styleUrls: ['./environment-banner.component.css'],
  imports: [
    NgIf
  ],
  standalone: true
})
export class EnvironmentBannerComponent {
    //bannerText = environment.bannerText;
    bannerText: string;

    ngOnInit(): void {
        this.bannerText = environment.bannerText;
    }
    // Assuming you have 'production' flag in environment files
}
