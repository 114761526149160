import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services';
import { Subject } from 'rxjs';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import { AuthenticationService } from 'clan-auth';
import { environment } from '@environment/environment';

export enum IdleUserTimes {
  IdleTimeInSeconds = environment.idleTimeInSeconds, // after this long user will start with idle mode
  CountdownTimeInSeconds = environment.countdownTimeInSeconds // after this long in idle mode user will be logged out
}

@Injectable({
  providedIn: 'root'
})
export class IdleUserService {
  private timeoutId: any;
  private countdownId: any;
  private countdownValue: number;

  constructor(
    private _snackbarService: SnackbarService,
    private _translateService: TranslateService,
    private authorizationService: AuthorizationService,
    private authService: AuthenticationService,
    private router: Router,
    ) {
  }


  reset() {
    clearTimeout(this.timeoutId);
    clearTimeout(this.countdownId);
    this.startIdleTimer();
  }

  startIdleTimer() {
    this.timeoutId = setTimeout(() => {
      this.startCountdown();
    }, IdleUserTimes.IdleTimeInSeconds * 1000);
  }

  startCountdown() {
    this.countdownValue = IdleUserTimes.CountdownTimeInSeconds;
    this.countdownId = setInterval(() => {
      this.countdownValue--;
      if (this.countdownValue <= 0) {
        clearInterval(this.countdownId);
        this.logout();
      }
    }, 1000);
  }

  logout() {
    this._snackbarService.handleOpenSnackbar(
      this._translateService.instant('cabinet.notifications.loggedOutDueToInactivity'),
      'Close',
      'error'
    );
    this.authorizationService.logout();
    this.authService.logout().subscribe(() => this.router.navigate(['/auth']));
  }
}