<mat-sidenav-container class="sidenav-container">
  @if(!singleAdminRole){
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [ngStyle]="{ 'width.px': isOpenNav ? '16rem' : '8rem' }"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
    [@openClose]="isOpenNav ? 'open' : 'closed'"
  >
    <div class="sidenav-container">
      <div class="sidenav-links">
        <div class="logoContainer">
          <img
            class="userLogo"
            [ngStyle]="{ width: isOpenNav ? '200px' : '100px' }"
            [src]="environment.config?.assets?.logo"
            alt="logo"
          />
        </div>
        <mat-nav-list class="nav-list">
          <mat-list-item
            [routerLink]="'dashboard'"
            [routerLinkActive]="['is-active']"
          >
            <div
              [ngClass]="isOpenNav ? 'sidenav-item' : 'sidenav-item-collapsed'"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  class="svgActive"
                  d="M12.2614 4.74751C12.1916 4.68075 12.0988 4.64349 12.0022 4.64349C11.9056 4.64349 11.8128 4.68075 11.743 4.74751L3.11328 12.9914C3.07663 13.0265 3.04748 13.0686 3.02757 13.1153C3.00767 13.1619 2.99744 13.2121 2.9975 13.2628L2.99609 21C2.99609 21.3978 3.15413 21.7794 3.43543 22.0607C3.71674 22.342 4.09827 22.5 4.49609 22.5H9.00078C9.1997 22.5 9.39046 22.421 9.53111 22.2804C9.67177 22.1397 9.75078 21.9489 9.75078 21.75V15.375C9.75078 15.2756 9.79029 15.1802 9.86062 15.1099C9.93094 15.0395 10.0263 15 10.1258 15H13.8758C13.9752 15 14.0706 15.0395 14.141 15.1099C14.2113 15.1802 14.2508 15.2756 14.2508 15.375V21.75C14.2508 21.9489 14.3298 22.1397 14.4705 22.2804C14.6111 22.421 14.8019 22.5 15.0008 22.5H19.5036C19.9014 22.5 20.283 22.342 20.5643 22.0607C20.8456 21.7794 21.0036 21.3978 21.0036 21V13.2628C21.0037 13.2121 20.9934 13.1619 20.9735 13.1153C20.9536 13.0686 20.9245 13.0265 20.8878 12.9914L12.2614 4.74751Z"
                />
                <path
                  class="svgActive"
                  d="M23.0114 11.4445L19.5052 8.09016V3C19.5052 2.80109 19.4261 2.61032 19.2855 2.46967C19.1448 2.32902 18.9541 2.25 18.7552 2.25H16.5052C16.3063 2.25 16.1155 2.32902 15.9748 2.46967C15.8342 2.61032 15.7552 2.80109 15.7552 3V4.5L13.0402 1.90406C12.7861 1.64719 12.4083 1.5 12 1.5C11.5931 1.5 11.2163 1.64719 10.9622 1.90453L0.991893 11.4436C0.70033 11.7249 0.663768 12.1875 0.92908 12.4922C0.995704 12.5691 1.07728 12.6317 1.16885 12.676C1.26041 12.7204 1.36005 12.7457 1.46169 12.7503C1.56334 12.755 1.66487 12.7389 1.7601 12.7031C1.85533 12.6672 1.94228 12.6124 2.01564 12.5419L11.7422 3.2475C11.812 3.18074 11.9048 3.14348 12.0014 3.14348C12.098 3.14348 12.1908 3.18074 12.2606 3.2475L21.9881 12.5419C22.1314 12.6793 22.3234 12.7543 22.5218 12.7504C22.7203 12.7466 22.9092 12.6641 23.047 12.5213C23.3349 12.2231 23.3109 11.7309 23.0114 11.4445Z"
                />
              </svg>
              <p [ngClass]="isOpenNav ? 'text' : 'text-collapsed'">
                {{ "cabinet.navigation.dashboard" | translate }}
              </p>
            </div>
          </mat-list-item>
          <mat-list-item
            [routerLink]="'offers/table'"
            [routerLinkActive]="['is-active']"
          >
            <div
              [ngClass]="isOpenNav ? 'sidenav-item' : 'sidenav-item-collapsed'"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  class="svgActive"
                  d="M23.25 8.25C23.2491 7.45462 22.9328 6.69206 22.3704 6.12964C21.8079 5.56722 21.0454 5.25087 20.25 5.25H18V4.5C17.9994 3.90345 17.7621 3.33152 17.3403 2.9097C16.9185 2.48787 16.3465 2.25062 15.75 2.25H8.25C7.65345 2.25062 7.08152 2.48787 6.65969 2.9097C6.23787 3.33152 6.00062 3.90345 6 4.5V5.25H3.75C2.95462 5.25087 2.19206 5.56722 1.62964 6.12964C1.06722 6.69206 0.750869 7.45462 0.75 8.25V10.5H23.25V8.25ZM16.5 5.25H7.5V4.5C7.5 4.30109 7.57902 4.11032 7.71967 3.96967C7.86032 3.82902 8.05109 3.75 8.25 3.75H15.75C15.9489 3.75 16.1397 3.82902 16.2803 3.96967C16.421 4.11032 16.5 4.30109 16.5 4.5V5.25Z"
                />
                <path
                  class="svgActive"
                  d="M15.75 12.375C15.75 12.6734 15.6315 12.9595 15.4205 13.1705C15.2095 13.3815 14.9234 13.5 14.625 13.5H9.375C9.07663 13.5 8.79048 13.3815 8.5795 13.1705C8.36853 12.9595 8.25 12.6734 8.25 12.375V12.1875C8.25 12.1378 8.23025 12.0901 8.19508 12.0549C8.15992 12.0198 8.11223 12 8.0625 12H0.75V18.75C0.75 19.5456 1.06607 20.3087 1.62868 20.8713C2.19129 21.4339 2.95435 21.75 3.75 21.75H20.25C21.0456 21.75 21.8087 21.4339 22.3713 20.8713C22.9339 20.3087 23.25 19.5456 23.25 18.75V12H15.9375C15.8878 12 15.8401 12.0198 15.8049 12.0549C15.7698 12.0901 15.75 12.1378 15.75 12.1875V12.375Z"
                />
              </svg>
              <p [ngClass]="isOpenNav ? 'text' : 'text-collapsed'">
                {{ "cabinet.navigation.offers" | translate }}
              </p>
            </div>
          </mat-list-item>
          <mat-list-item
            [routerLink]="'master-data'"
            [routerLinkActive]="['is-active']"
          >
            <div
              [ngClass]="isOpenNav ? 'sidenav-item' : 'sidenav-item-collapsed'"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  class="svgActive"
                  d="M17.25 4.5H6.75C6.55109 4.5 6.36032 4.42098 6.21967 4.28033C6.07902 4.13968 6 3.94891 6 3.75C6 3.55109 6.07902 3.36032 6.21967 3.21967C6.36032 3.07902 6.55109 3 6.75 3H17.25C17.4489 3 17.6397 3.07902 17.7803 3.21967C17.921 3.36032 18 3.55109 18 3.75C18 3.94891 17.921 4.13968 17.7803 4.28033C17.6397 4.42098 17.4489 4.5 17.25 4.5Z"
                />
                <path
                  class="svgActive"
                  d="M18.75 6.75H5.25C5.05109 6.75 4.86032 6.67098 4.71967 6.53033C4.57902 6.38968 4.5 6.19891 4.5 6C4.5 5.80109 4.57902 5.61032 4.71967 5.46967C4.86032 5.32902 5.05109 5.25 5.25 5.25H18.75C18.9489 5.25 19.1397 5.32902 19.2803 5.46967C19.421 5.61032 19.5 5.80109 19.5 6C19.5 6.19891 19.421 6.38968 19.2803 6.53033C19.1397 6.67098 18.9489 6.75 18.75 6.75Z"
                />
                <path
                  class="svgActive"
                  d="M19.6467 21H4.35328C3.79565 20.9994 3.26103 20.7776 2.86672 20.3833C2.47241 19.989 2.25062 19.4544 2.25 18.8967V9.60328C2.25062 9.04565 2.47241 8.51103 2.86672 8.11672C3.26103 7.72241 3.79565 7.50062 4.35328 7.5H19.6467C20.2044 7.50062 20.739 7.72241 21.1333 8.11672C21.5276 8.51103 21.7494 9.04565 21.75 9.60328V18.8967C21.7494 19.4544 21.5276 19.989 21.1333 20.3833C20.739 20.7776 20.2044 20.9994 19.6467 21Z"
                />
              </svg>
              <p [ngClass]="isOpenNav ? 'text' : 'text-collapsed'">
                {{ "cabinet.navigation.masterData" | translate }}
              </p>
            </div></mat-list-item
          >
          @if(isReportSiteEnabled){
          <mat-list-item
            [routerLink]="'report'"
            [routerLinkActive]="['is-active']"
          >
            <div
              [ngClass]="isOpenNav ? 'sidenav-item' : 'sidenav-item-collapsed'"
            >
            <svg width="35" height="35" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="svgActive" d="M4.53006 16.7363C4.14005 16.3463 4.14005 15.7163 4.53006 15.3263L12.08 7.77628C12.9701 6.88626 14.3401 6.64627 15.4801 7.18625L21.44 9.9863C21.81 10.1663 22.2701 10.0863 22.57 9.78629L30.0501 2.3063C30.44 1.91629 31.08 1.91629 31.4701 2.3063C31.8601 2.69626 31.8601 3.32626 31.4701 3.71628L23.9801 11.2063C23.0901 12.0963 21.7201 12.3363 20.58 11.7963L14.63 8.99631C14.25 8.81625 13.7901 8.89627 13.4901 9.18625L5.95004 16.7363C5.75003 16.9362 5.50003 17.0263 5.24008 17.0263C4.98007 17.0263 4.73007 16.9362 4.53006 16.7363Z" />
              <path class="svgActive" d="M16.75 19.5863V31.9863C16.75 33.0963 15.85 33.9863 14.75 33.9863H12.75C11.65 33.9863 10.75 33.0963 10.75 31.9863V19.5863C10.75 18.4863 11.65 17.5863 12.75 17.5863H14.75C15.85 17.5863 16.75 18.4863 16.75 19.5863Z" />
              <path class="svgActive" d="M33.75 12.5663V31.9863C33.75 33.0963 32.85 33.9863 31.75 33.9863H29.75C28.65 33.9863 27.75 33.0963 27.75 31.9863V12.5663C27.75 11.4663 28.65 10.5663 29.75 10.5663H31.75C32.85 10.5663 33.75 11.4663 33.75 12.5663Z" />
              <path class="svgActive" d="M8.25 28.5264V31.9864C8.25 33.0964 7.35004 33.9864 6.25 33.9864H4.25C3.15002 33.9864 2.25 33.0964 2.25 31.9864V28.5264C2.25 27.4264 3.15002 26.5264 4.25 26.5264H6.25C7.35004 26.5264 8.25 27.4264 8.25 28.5264Z" />
              <path class="svgActive" d="M25.25 23.0663V31.9863C25.25 33.0963 24.35 33.9863 23.25 33.9863H21.25C20.15 33.9863 19.25 33.0963 19.25 31.9863V23.0663C19.25 21.9663 20.15 21.0663 21.25 21.0663H23.25C24.35 21.0663 25.25 21.9663 25.25 23.0663Z" />
              </svg>
              
              <p [ngClass]="isOpenNav ? 'text' : 'text-collapsed'">
                {{ "cabinet.navigation.report" | translate }}
              </p>
            </div></mat-list-item
          >}
        </mat-nav-list>
      </div>
      <div [ngClass]="isOpenNav ? 'max-min' : 'max-min-collapsed'">
        <div class="max-min-content" (click)="menuSlideOpen()">
          @if(notDesktopView){
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 24 24"
            fill="none"
            class="rotated"
            [class.rotate]="!isOpenNav"
          >
            <path
              class="svgActive"
              d="M2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25C6.61547 2.25 2.25 6.61547 2.25 12ZM12.218 7.7175C12.2879 7.78687 12.3435 7.86934 12.3816 7.96019C12.4196 8.05105 12.4394 8.14851 12.4398 8.24702C12.4402 8.34552 12.4212 8.44314 12.3838 8.5343C12.3465 8.62545 12.2916 8.70836 12.2222 8.77828L9.76969 11.25H16.0312C16.2302 11.25 16.4209 11.329 16.5616 11.4697C16.7022 11.6103 16.7812 11.8011 16.7812 12C16.7812 12.1989 16.7022 12.3897 16.5616 12.5303C16.4209 12.671 16.2302 12.75 16.0312 12.75H9.76969L12.2222 15.2217C12.2916 15.2917 12.3465 15.3747 12.3838 15.4659C12.4211 15.5571 12.4401 15.6548 12.4396 15.7533C12.4392 15.8519 12.4194 15.9494 12.3812 16.0402C12.3431 16.1311 12.2875 16.2136 12.2175 16.283C12.1475 16.3523 12.0645 16.4073 11.9733 16.4446C11.8821 16.4819 11.7845 16.5009 11.6859 16.5004C11.5874 16.5 11.4899 16.4801 11.399 16.442C11.3081 16.4039 11.2256 16.3483 11.1562 16.2783L7.43484 12.5283C7.29544 12.3878 7.21722 12.1979 7.21722 12C7.21722 11.8021 7.29544 11.6122 7.43484 11.4717L11.1562 7.72172C11.2256 7.65164 11.3082 7.59593 11.3991 7.55778C11.49 7.51964 11.5876 7.4998 11.6862 7.49941C11.7849 7.49902 11.8826 7.51808 11.9738 7.5555C12.0651 7.59292 12.148 7.64797 12.218 7.7175Z"
              fill="var(--primary)"
            />
          </svg>
          }
        </div>
      </div>
    </div>
  </mat-sidenav>

  <!-- } -->}
  <mat-sidenav-content [class]="notDesktopView ? 'ml-0' : 'ml-16'">
    <mat-toolbar class="toolbar">
      @if(!singleAdminRole){ @if (isHandset$ | async) {
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="!singleAdminRole ? drawer.toggle() : ''"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      }}

      <span></span>

      <div class="user-nav">
        <span class="user-info__title header-block__title">
          {{ userName }}
        </span>
        <span class="userInitials">
          {{ initials }}
        </span>
        <!-- Profil Icon -->
        <button class="menu-btn">
          <mat-icon class="default-icon primary">account_circle</mat-icon>
        </button>
        <button
          class="menu-btn"
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon class="default-icon">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="navMenu">
          <div class="nav-menu">
            <div class="nav-menu__languages">
              <div class="nav-menu__languages__box">
                <span class="navItem">{{
                  "cabinet.home.infoField.language" | translate
                }}</span>
                <div>
                  <button
                    [class]="
                      currentLanguage === 'en' ? 'navItem bold' : 'navItem'
                    "
                    (click)="switchLanguage('en')"
                  >
                    EN
                  </button>
                  <button
                    [class]="
                      currentLanguage === 'de' ? 'navItem bold' : 'navItem'
                    "
                    (click)="switchLanguage('de')"
                  >
                    DE
                  </button>
                </div>
              </div>
            </div>
            <div class="nav-menu__account">
              <a routerLink="my-account"
                >{{ "auth.userManagement.myAccount" | translate }}
                <mat-icon class="account-icon">settings</mat-icon></a
              >
              @if(adminRole){
              <a routerLink="users"
                >{{ "auth.userManagement.userManagement" | translate }}
                <mat-icon class="account-icon">settings</mat-icon></a
              >
              <a routerLink="admin-configuration"
                >{{ "auth.userManagement.configuration" | translate }}
                <mat-icon class="account-icon">settings</mat-icon></a
              >
              }
            </div>
          </div>
        </mat-menu>
        <button class="menu-btn" (click)="doLogout()">
          <mat-icon class="default-icon">logout</mat-icon>
        </button>
      </div>
    </mat-toolbar>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
